<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-skeleton-loader
        type="chip"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <v-toolbar-title v-else class="headline font-weight-bold"
        >Campaign Notification
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-row v-show="showSearch" align="center" style="max-width: 300px">
        <v-text-field
          class="search"
          ref="searchField"
          flat
          hide-details
          solo
          rounded
          clearable
          background-color="grey lighten-3"
          label="Search Campaign"
          v-model="filter.search"
          @input="search"
        ></v-text-field>
      </v-row>

      <v-skeleton-loader
        type="actions"
        transition="fade-transition"
        v-if="loading"
      ></v-skeleton-loader>

      <template v-else>
        <v-select
          class="ml-10"
          hide-details
          dense
          item-text="label"
          item-value="value"
          v-model="defaultFilter"
          :items="filterBy"
          style="max-width: 200px"
          @change="filterCampaign"
        />

        <v-btn
          depressed
          class="ml-2"
          :color="sortOrder ? 'primary' : ''"
          @click="
            sortOrder = true
            filterCampaign()
          "
        >
          <v-icon>{{ icons.arrowUp }}</v-icon>
        </v-btn>
        <v-btn
          depressed
          class="ml-2"
          :color="!sortOrder ? 'primary' : ''"
          @click="
            sortOrder = false
            filterCampaign()
          "
        >
          <v-icon>{{ icons.arrowDown }}</v-icon>
        </v-btn>

        <v-btn class="ml-5" icon @click="showSearchField">
          <img src="@/assets/icons/search.svg" alt height="17.7px" />
        </v-btn>
      </template>
    </v-app-bar>

    <div class="d-flex my-6">
      <v-spacer></v-spacer>
      <template v-if="!loading">
        <v-btn color="primary" @click="$router.push({ name: 'campaigns.send' })"
          ><v-icon left dark> {{ icons.add }} </v-icon>
          Create Campaign Notifications
        </v-btn>
      </template>
    </div>
    <div
      class="mt-12 mx-4"
      id="user-list"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="loading"
      infinite-scroll-distance="100"
    >
      <v-simple-table>
        <thead>
          <tr>
            <th width="25%" class="text-left">Title</th>
            <th width="35%" class="text-left">Contents</th>
            <th width="10%" class="text-left">Status</th>
            <th width="15%" class="text-left">Date Sent</th>
            <th width="15%" class="text-left">Date Created</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="campaign in campaigns"
            :key="campaign.id"
            class="clickable"
            @click="
              $router.push({
                name: 'campaign.details',
                params: { id: campaign.id },
              })
            "
          >
            <td>
              <p>{{ campaign.title }}</p>
            </td>
            <td>
              <p>{{ campaign.contents }}</p>
            </td>
            <td>
              <p>{{ campaign.status }}</p>
            </td>
            <td>
              <p>{{ campaign.sentDate }}</p>
            </td>
            <td>
              <p>{{ campaign.addedDate }}</p>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </div>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color" right>
      {{ snackbar.message }}
      <v-btn color="white" text @click="snackbar.show = false">Ok</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import {
  mdiDotsVertical,
  mdiUnfoldMoreHorizontal,
  mdiMagnify,
  mdiAccountCircleOutline,
  mdiChevronRight,
  mdiFileExcel,
  mdiPlus,
  mdiArrowUp,
  mdiArrowDown,
} from '@mdi/js'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import infiniteScroll from 'vue-infinite-scroll'
import debounce from 'lodash/debounce'

export default {
  name: 'Campaign',
  mixins: [ControlsMixin],

  directives: {
    infiniteScroll,
  },

  components: {
    AppBarNavIcon,
  },

  data() {
    return {
      loading: false,
      showSearch: false,
      filter: {
        search: '',
      },
      icons: {
        settings: mdiDotsVertical,
        sort: mdiUnfoldMoreHorizontal,
        search: mdiMagnify,
        view: mdiAccountCircleOutline,
        arrow: mdiChevronRight,
        export: mdiFileExcel,
        add: mdiPlus,
        arrowUp: mdiArrowUp,
        arrowDown: mdiArrowDown,
      },
      snackbar: {
        show: false,
        message: null,
        color: '',
      },
      defaultFilter: 'title',
      sortOrder: true,
      filterBy: [
        {
          value: 'title',
          label: 'Title',
        },
        {
          value: 'contents',
          label: 'Contents',
        },
        {
          value: 'is_sent',
          label: 'Status',
        },
        {
          value: 'date_Sent',
          label: 'Date Sent',
        },
        {
          value: 'created_at',
          label: 'Date Created',
        },
      ],
    }
  },

  created() {
    this.clearCampaign()
    this.fetchCampaigns(1)
  },

  computed: {
    ...mapState({
      campaigns: (state) => state.campaign.list,
      listMeta: (state) => state.campaign.listMeta,
    }),

    paginationLength() {
      return (this.listMeta.total / this.listMeta.per_page) | 0
    },
  },

  methods: {
    ...mapActions({
      getCampaigns: 'campaign/getCampaigns',
    }),

    ...mapMutations({
      clearCampaign: 'campaign/clearCampaignList',
    }),

    pageChanged(page) {
      this.clearCampaign()
      this.fetchCampaigns(page)
    },

    filterCampaign(filterValue) {
      this.clearCampaign()
      this.fetchCampaigns(null, filterValue)
    },

    loadMore() {
      if (
        this.listMeta &&
        this.listMeta.current_page < this.listMeta.last_page
      ) {
        this.fetchCampaigns(this.listMeta.current_page + 1)
      }
    },

    search: debounce(function () {
      this.clearCampaign()
      this.fetchCampaigns()

      setTimeout(() => {
        this.$refs.searchField.$refs.input.focus()
      }, 500)
    }, 1600),

    async fetchCampaigns(page = 1) {
      if (this.loading) return
      let params = {
        page,
        sort: this.sortOrder
          ? `${this.defaultFilter}`
          : `-${this.defaultFilter}`,
      }
      if (this.filter.search) {
        params.search = this.filter.search
      }
      this.loading = true
      await this.getCampaigns(params)
      this.loading = false
    },

    async showSearchField() {
      this.showSearch = !this.showSearch
      await this.$nextTick()
      this.$refs.searchField.$refs.input.focus()
    },
  },
}
</script>
